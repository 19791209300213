/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  'use strict';
  var Sage = {
    // All pages
    common: {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $('.faqs a').click(function () {
          $('.answer').not($(this).next('.answer')).slideUp(300);
          $(this).next('.answer').slideToggle(300);
          return false;
        });

        var resizeTimer;

        $('a[href^="#"]')
          .not('a[href="#"]')
          .click(function () {
            var headerHeight = $('header').outerHeight() + 15,
              target = $(this).attr('href');

            $('html, body').animate(
              {
                scrollTop: $(target).offset().top - headerHeight,
              },
              1000,
            );
          });

        //alt tags if not entered in WP
        $('img').each(function () {
          $(this).attr('alt', document.title);
        });

        $('.menu a').each(function () {
          $(this).attr('title', 'Link to: ' + $(this).text());
        });

        $(window).on('resize', function (e) {
          clearTimeout(resizeTimer);
          resizeTimer = setTimeout(function () {
            createVariables();
          }, 250);
        });

        var $toggle = $('.menu-toggle'),
          $nav = $('.main-navigation');
        $toggle.click(function () {
          $nav.slideToggle(300);
          return false;
        });

        $('.wysiwyg p iframe').each(function () {
          $(this).wrap('<div class="iframe-wrapper"></div>');
          $(this).parents('p').addClass('mb0');
        });

        function createVariables() {
          var headerHeight = $('header').outerHeight();
          vein.inject(':root', {
            '--headerHeight': headerHeight + 'px',
          });


          var offset = $('.simple-banner').outerHeight();

          $('header').attr('style', 'top:' + offset + 'px');
        }

        $(document).ready(function () {
          $('html').delay(1000).animate(
            {
              opacity: 1,
            },
            500,
          );
        });

        $('p, a').each(function () {
          var text = $(this).html();
          var string = '&nbsp;';

          if (text.includes(string)) {
            $(this).html($(this).html().replace(string, ''));
          }
        });

        createVariables();

        $(window).load(function () {
          createVariables();
        });
      },
    }, // Home page
    single_office: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
        var mapID = $('.map').attr('id'),
          location = $('.header h1').text(),
          address = $('.address').text(),
          dataLat = $('.map').attr('data-lat'),
          dataLng = $('.map').attr('data-lng');
        console.log(mapID, dataLat, dataLng);

        function mapinitialize() {
          var latlng = new google.maps.LatLng(dataLat, dataLng);
          var myOptions = {
            zoom: 18,
            center: latlng,
            scrollwheel: true,
            scaleControl: true,
            disableDefaultUI: false,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            // Google Map Color Styles
            styles: [
              {
                stylers: [
                  {
                    visibility: 'on',
                  },
                ],
              },
              {
                featureType: 'landscape',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'on',
                  },
                  {
                    hue: '#ffffff',
                  },
                  {
                    saturation: -100,
                  },
                  {
                    lightness: 100,
                  },
                ],
              },
              {
                featureType: 'water',
                stylers: [
                  {
                    visibility: 'on',
                  },
                  {
                    lightness: 75,
                  },
                  {
                    saturation: -100,
                  },
                ],
              },
              {
                featureType: 'administrative.province',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'administrative.country',
                elementType: 'geometry',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'water',
                elementType: 'labels',
                stylers: [
                  {
                    visibility: 'off',
                  },
                ],
              },
              {
                featureType: 'road.local',
                elementType: 'geometry.fill',
                stylers: [
                  {
                    visibility: 'off',
                  },
                  {
                    color: '#000000',
                  },
                  {
                    lightness: 90,
                  },
                ],
              },
            ],
          };
          var map = new google.maps.Map(
            document.getElementById(mapID),
            myOptions,
          );
          var image = '/wp-content/themes/couturemedspa/dist/img/map-pin.png';
          var image = new google.maps.MarkerImage(
            '/wp-content/themes/couturemedspa/dist/img/map-pin.png',
            null,
            null,
            null,
            new google.maps.Size(75, 108),
          );
          var marker = new google.maps.Marker({
            map: map,
            icon: image,
            position: map.getCenter(),
          });
          var contentString =
            '<div class="text-center"><h2 class="mt8 mb-3">' +
            location +
            '</h2><p>' +
            address +
            '</p></div><a class="view" href="http://maps.google.com/?q=Couture Med Spa ' +
            address +
            '" target="blank">View on Google Maps</a>';
          var infowindow = new google.maps.InfoWindow({
            content: contentString,
          });
          google.maps.event.addDomListener(window, 'resize', function () {
            var center = map.getCenter();
            google.maps.event.trigger(map, 'resize');
            map.setCenter(center);
          });
          marker.addListener('click', function () {
            infowindow.open(map, marker);
          });
        }
        mapinitialize();
      },
    }, // About us page, note the change from about-us to about_us.
    single_service: {
      init: function () {
        // JavaScript to be fired on the about us page
        var field = 'lp',
          url = window.location.href,
          document;

        if (url.indexOf('?' + field + '=') !== -1) {
          $(document).ready(function () {
            objectFitImages();
          });
          //                    $('html').addClass('lp-mode');
          //                    $('#content').css('margin-top', $('header').outerHeight());
          //     $(window).on('resize', function () {
          //      clearTimeout(resizeTimer);
          //      resizeTimer = setTimeout(function () {
          //       //                            $('#content').css('margin-top', $('header').outerHeight());
          //      }, 250);
          //     });
          $('.testimonials').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  dots: true,
                },
              },
              {
                breakpoint: 560,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  dots: true,
                },
              },
            ],
          });
          $('.faqs a').click(function () {
            $('.answer').not($(this).next('.answer')).slideUp(300);
            $(this).next('.answer').slideToggle(300);
            return false;
          });
        }
      },
    },
    page_template_page_pricing: {
      init: function () {
        // JavaScript to be fired on the about us page
        var field = 'lp',
          url = window.location.href,
          document;

        if (url.indexOf('?' + field + '=') !== -1) {
          //                    $('html').addClass('lp-mode');
          //                    $('#content').css('margin-top', $('header').outerHeight());
          //            if($('.ie, .edge').length){
          //
          //
          //
          //
          //            }
          $(document).ready(function () {
            objectFitImages();
          });
          if ($(window).width() < 768) {
            $('.page-template-page-pricing .site-content').css(
              'padding-bottom',
              '0',
            );
          } else {
            $('.page-template-page-pricing .site-content').css(
              'padding-bottom',
              $('.additional-info .wrap').outerHeight(),
            );
          }
          $('.modal .close-toggle').click(function () {
            $('body').removeClass('start-checkout');
            return false;
          });
          $('.form-toggle').click(function () {
            $('html, body').animate(
              {
                scrollTop: $('#gform_wrapper_3').offset().top - 100,
              },
              1000,
            );
            return false;
          });
          $('.pricing-toggle').click(function () {
            $(this).parents('.tier').addClass('show');
            return false;
          });
          $('.tier .close-toggle').click(function () {
            $(this).parents('.tier').removeClass('show');
            return false;
          });
          $('.member-toggle').click(function () {
            if ($(window).width() > 768) {
              $('body').addClass('start-checkout');
            } else {
              window.location = '/membership';
            }
            return false;
          });

          var resizeTimer;

          $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
              if ($(window).width() < 768) {
                $('.page-template-page-pricing .site-content').css(
                  'padding-bottom',
                  '0',
                );
              } else {
                $('.page-template-page-pricing .site-content').css(
                  'padding-bottom',
                  $('.additional-info .wrap').outerHeight(),
                );
              }
            }, 250);
          });

          $('.testimonials').slick({
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            dots: true,
            responsive: [
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true,
                },
              },
              {
                breakpoint: 560,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true,
                },
              },
            ],
          });
          $('.faqs a').click(function () {
            $('.answer').not($(this).next('.answer')).slideUp(300);
            $(this).next('.answer').slideToggle(300);
            return false;
          });
        }
      },
    },
  };
  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');
      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (
        i,
        classnm,
      ) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });
      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    },
  };
  // Load Events
  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
